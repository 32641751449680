@import "variables";
.jv-error {
    display: none !important;
}

select.Select {
    width: 100%;
    padding: 0.5rem;
}

.select2-container {
    font-size: 0.75rem !important;
    ś .select2-results__options {
        .select2-results__option--highlighted {
            background-color: #ebf5ff !important;
            color: #333 !important;
        }
        .select2-results__option[aria-selected="true"] {
            background-color: #f5faff !important;
        }
    }
    .select2-selection--single,
    .select2-selection--multiple {
        border-radius: 0 !important;
        color: #999 !important;
        padding: 0.5rem;
        padding-left: 0;
        width: 100% !important;
        border: 1px solid #777;
    }
    .select2-selection--multiple {
        padding: 0;
    }
    .select2-selection--multiple .select2-selection__choice {
        background: var(--blue);
        border: 0 !important;
        border-radius: 0 !important;
        padding: 0 5px 0 0;
        color: #fff;
        font-size: 12px;
        margin: 1px 1px 1px 0;
    }
    .select2-selection__clear {
        color: #777 !important;
        display: inline-block;
        font-size: 18px;
        line-height: 1;
    }
    .select2-results__group {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background-color: #fff;
        color: #666;
        font-weight: 700;
        cursor: default;
        display: block;
        padding: 8px 10px;
    }
    .select2-results__options {
        color: #666;
    }
    .select2-selection--multiple .select2-selection__choice__remove {
        background: var(--dark-blue);
        padding: 9px 5px;
        color: #fff;
        font-size: 12px;
        margin-right: 5px;
    }
    .select2-selection--multiple .select2-selection__choice__remove:hover {
        color: #fff;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__rendered {
        padding: 2px 5px 0 2px;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field {
        text-indent: 8px;
    }

    .select2-container--default .select2-search--inline .select2-search__field::placeholder {
        color: #5e5e5e;
        line-height: 34px;
        padding-left: 0px;
        padding-right: 10px;
        max-width: 95%;
        font-family: Lato, sans-serif;
    }
    .select2-selection--multiple .select2-selection__clear {
        margin-right: 0;
    }
    .select2-dropdown {
        border-radius: 0;
    }
    .select2-selection--single .select2-selection__arrow {
        padding: 0;
        top: 0;
        bottom: 0;
        width: 25px;
        height: auto;
    }
    .select2-selection--single .select2-selection__rendered {
        line-height: 17px;
    }
}

.select2-container .select2-selection--single {
    height: auto;
}

.select2-container--default .validation-error {
    .cke_editor_content {
        border-color: #ff4136 !important;
    }
    .select2-selection {
        border-color: #ff4136 !important;
    }
}

.input-element.validation-error {
    .select2-selection {
        border: solid 1px #ff4136 !important;
    }
}

.Zebra_DatePicker,
.Zebra_DatePicker .dp_footer .dp_hover,
.Zebra_DatePicker .dp_header .dp_hover,
.Zebra_DatePicker td.dp_hover,
.Zebra_DatePicker td.dp_selected {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 2px 5px 0 2px;
}

.select2-container--default .select2-search--inline .select2-search__field::placeholder {
    color: #5e5e5e;
    line-height: inherit;
    padding-left: 0px;
    padding-right: 10px;
    max-width: 95%;
    font-family: Lato, sans-serif;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search__field {
    text-indent: 8px !important;
}

.select2-container--default .select2-results__group {
    padding: 6px 6px 6px 9px !important;
}

.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1.5em !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
    margin-right: 0;
    margin-top: 8px;
}

.select2-container--default {
    .select2-search--dropdown {
        .select2-search__field {
            border: 1px solid #777;
        }
    }
    .select2-results__option[aria-selected="true"] {
        background-color: #eee;
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: #0b67f9;
        color: #fff;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
//Supports Mozilla
input[type="number"] {
    -moz-appearance: textfield;
}

// Formularz
.notice {
    display: none;
}

[data-active-step] {
    &.margin {
        padding-top: 2rem;
    }

    @media screen and (min-width: 80em) {
        &.margin {
            padding-top: 1rem;
        }
    }
}

[data-active-step="1"] {
    .notice[data-step="1"] {
        display: block;
    }

    &.margin {
        padding-top: 0.5rem;
    }

    @media screen and (min-width: 768px) {
        &.margin {
            padding-top: 0;
        }
    }

    .good-to-know {
        .container {
            display: block;
        }

        .spoiler {
            display: none;
        }

        &[data-hidden="true"] {
            .container {
                display: none;
            }

            .spoiler {
                display: block;
            }
        }
    }
}

.step-wizard {
    .done {
        display: none;
    }

    &.active {
        pointer-events: auto;
    }

    // .bubble {
    //     background-color: var(--readable-small-gray);
    // }

    .text {
        color: var(--readable-small-gray);
    }

    &.active {
        // .bubble {
        //     background-color: var(--blue);
        // }

        .text {
            font-weight: 600;
            color: var(--near-black);
        }
    }

    &.prev {
        pointer-events: auto;
        cursor: pointer;

        .bubble {
            display: none;
        }

        .done {
            display: flex;
        }

        .text {
            color: var(--near-black);
        }
    }

    pointer-events: none;
}

.uploader {
    .uploading {
        display: none;
    }

    .error {
        display: none;
    }

    &[data-is-uploading="true"] {
        .button {
            display: none;
        }

        .uploading {
            display: flex;
        }
    }

    &[data-files-length="0"] {
        .files-list {
            display: none;
        }

        .complete {
            display: none;
        }
    }
}

.tabs-next,
#do-publish {
    &:not(.active):hover {
        background: var(--near-black);
        border-color: var(--near-black);
    }

    .loader {
        display: none;
    }

    &.active .loader {
        display: flex;
    }

    &.active .w-100 {
        visibility: hidden;
    }
}

[data-price-id] {
    .body {
        background: var(--near-white);
        color: var(--near-black);

        &:hover {
            background: var(--light-gray);
        }

        .price-click {
            color: var(--blue);

            &:hover {
                background: var(--blue);
                border-color: var(--blue);
                color: white;
            }
        }
    }

    &.active {
        .body {
            background: var(--blue);
            color: white;

            .price-click {
                color: white;
                border-color: white;

                &:hover {
                    background: white;
                    color: var(--blue);
                }
            }
        }
    }
}
